/*----- FONTS ---------

font-family: 'Roboto', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Saira Semi Condensed', sans-serif;

-----------*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  max-width: 1800px;
  margin: 0px auto; }

h1,
h2,
label {
  font-family: 'Saira Semi Condensed', sans-serif; }

h2 {
  font-size: 2.25rem; }

.gold {
  color: #866c45; }

p {
  font-family: 'Montserrat', sans-serif; }

label {
  font-weight: 500;
  font-size: 1.0625rem; }

.logo {
  width: 100%;
  max-width: 198px;
  height: auto; }

.navbar {
  padding: 1.2rem 1rem;
  flex-wrap: nowrap; }

.amount label {
  font-size: 1.2rem; }

#amountset {
  color: #5ea577;
  font-weight: 600;
  font-size: 1.2rem; }

.m-45 {
  margin: 0.9rem 1.9rem; }

.phonenumber {
  font-family: 'Saira Semi Condensed', sans-serif;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500; }

.phonenav {
  min-width: 58%;
  text-align: right; }

.mainheading {
  background-color: rgba(46, 52, 60, 0.97);
  margin: 55% 1rem 0 1rem;
  padding: 2rem 2rem; }

.icons .spacer {
  height: 120px; }

.mainheading h1 {
  font-size: 3.25rem; }

.mainheading p {
  color: white; }

.nowrap {
  white-space: nowrap; }

.beigebar {
  background-color: #e2e2db;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-size: 1.25rem;
  font-weight: 500; }

.beigerows {
  margin: auto;
  width: 200px;
  padding: 0.5rem 0; }

.beigebar img {
  margin-right: 0.9rem; }

.green h2 {
  font-size: 2.375rem; }

.darkbar {
  background-color: #2e343c; }

.stepnumber {
  font-family: ff-more-web-pro, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.75rem;
  line-height: 3.75rem;
  position: absolute;
  top: 0;
  left: 5%;
  color: #c2bd9f; }

.stepnumber2,
.stepnumber3,
.stepnumber4 {
  left: 5%; }

.green {
  background-color: #3d6165; }

.button-white {
  border: 1px solid #fff;
  border-radius: 2rem;
  padding: 0.3rem 2rem;
  margin: 1rem;
  color: white;
  font-size: 1.4rem;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-weight: 600; }

a.button-white:hover {
  color: #2e343c;
  text-decoration: none;
  background-color: white; }

.footer {
  border-bottom: 1px solid #d0ccb7;
  margin-bottom: 3rem; }

.footer p {
  font-size: 0.9rem; }

#wrapper {
  background-color: white; }

.risktext {
  color: white;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-size: 1.5rem;
  font-weight: 500; }

.riskimage {
  width: 100%;
  max-width: 167px;
  height: auto; }

.main {
  background-image: url("/public/img/background.jpg");
  background-size: auto 40%;
  background-repeat: no-repeat;
  background-position: 25% top; }

.form {
  background-color: rgba(240, 240, 240, 0.96);
  padding: 1rem;
  border-radius: 0.3rem; }

.form-control {
  font-size: 1.25rem;
  padding: 1.4rem 0.75rem; }

input::placeholder {
  color: #bcbcbc !important; }

.formwrapper {
  margin: 61px auto;
  width: 100%; }

.formheader {
  background-color: #5ea577; }

.formheader h1,
.formheader h2 {
  text-transform: uppercase;
  font-weight: 600; }

.formheader h1 {
  color: white;
  font-size: 1.75rem;
  padding: 0.8rem 0 0rem 0;
  line-height: 1.75rem; }

.formheader h2 {
  font-size: 1.25rem;
  color: black;
  line-height: 1.25rem;
  padding-bottom: 0.8rem; }

.btn-primary {
  background: #2a8861;
  background: linear-gradient(0deg, #2a8861 0%, #75b181 100%);
  border: 2px solid #2e463b;
  border-radius: 2rem;
  font-family: 'Saira Semi Condensed', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.4rem 2rem 0.5rem 2rem; }

.btn-primary:hover {
  border: 2px solid #2e463b;
  background: linear-gradient(0deg, #2a8861 0%, #75b181 100%); }

.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 4px;
  /* Specified height */
  padding: 0px;
  background: #b7b7b7;
  /* Grey background */
  outline: none;
  /* Remove outline */
  border: none; }

.slider:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  background: #b7b7b7;
  /* Grey background */
  resize: none;
  outline: none; }

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  background: #2a8861;
  background: linear-gradient(0deg, #2a8861 0%, #75b181 100%);
  border-radius: 0.5rem;
  border: 1px solid #2e463b;
  width: 60px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  cursor: pointer;
  /* Cursor on hover */ }

.slider::-moz-range-thumb {
  background: #2a8861;
  background: linear-gradient(0deg, #2a8861 0%, #75b181 100%);
  border-radius: 0.5rem;
  border: 1px solid #2e463b;
  width: 60px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  cursor: pointer;
  /* Cursor on hover */ }

@media (min-width: 375px) {
  .phonenumber {
    font-family: 'Saira Semi Condensed', sans-serif;
    color: #fff;
    font-size: 1rem;
    font-weight: 500; } }

@media (min-width: 576px) {
  .mainheading h1 {
    font-size: 4rem; }
  .btn-primary {
    padding: 0.4rem 4rem 0.5rem 4rem;
    font-size: 1.6875rem; }
  .button-white {
    padding: 0.3rem 3.5rem; }
  .form {
    padding: 2rem; }
  .formheader {
    background-color: #5ea577;
    margin: 0 13%; }
  .mainheading {
    margin: 25% 1rem 0 1rem; }
  .phonenumber {
    font-family: 'Saira Semi Condensed', sans-serif;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500; }
  .phonenav {
    min-width: 210px; } }

@media (min-width: 768px) {
  .navbar {
    padding: 1.2rem 4rem; }
  .mainheading {
    margin: 25% 2rem 0 2rem;
    padding: 2rem 4rem; }
  .mainheading h1 {
    font-size: 4.87rem; } }

@media (min-width: 992px) {
  .main {
    background-image: url("/public/img/background-squashed.jpg");
    background-size: cover; }
  .mainhead {
    width: 50%;
    max-width: 807px; }
  .mainheading {
    margin: 25% 0 0 0; }
  .mainheading h1 {
    font-size: 4rem; }
  .formwrapper {
    width: 526px; }
  .stepnumber {
    left: 0%; }
  .stepnumber1 {
    left: 5%; }
  .beigerows {
    margin: 0;
    width: auto;
    padding: 0; } }

@media (min-width: 1200px) {
  .stepnumber {
    left: 5%; }
  .stepnumber1 {
    left: 10%; }
  .mainheading h1 {
    font-size: 4.87rem; } }

#form-after {
  display: none; }

#form-loading {
  display: none;
  text-align: center;
  margin: 4rem; }
